import { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';

const Redirect = () => {
  const { uuid } = useParams();
  const history = useHistory();
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/users/signup/uuid/${uuid}/`)
      .then((response) => {
        if (response.status === 404) {
          history.push('/404');
        }
        return response.json()
      })
      .then(({ invite_url }) => {
        if(invite_url) window.location.replace(invite_url)
      })
  }, [uuid, history]);

  return (
    <div className="view">
      <p>Redirecting...</p>
    </div>
  );
};

export default Redirect;
