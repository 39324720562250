import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { format } from "date-fns";
import { getEventType, getEventData, postMeetings } from "../../api/calendars";
import Calendar from "react-calendar";
import { createPaidMeetingCheckout } from "../../compositions/http/payments";
import Rectangle1 from "../../assets/Rectangle1.png"
import Rectangle2 from "../../assets/Rectangle2.png"
import SmrtlinksLogo from "../../assets/SmrtlinksLogo.png"
import "./Calendar.styles.scss";
import "./ReactCalendar.styles.scss";
import "react-calendar/dist/Calendar.css";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const CalendarView = ({ catchError, clearError }) => {
  const { event } = useParams();
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(new Date());
  const [currBooking, setCurrBooking] = useState({
    date: null,
    startHour: null,
  });
  const [listOfDates, setListOfDates] = useState([]);
  const [listOfQuestions, setListOfQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [paymentInfo, setPaymentInfo] = useState({ paid: false, price: null, currency: null, event: null });
  const [team, setTeam] = useState(null);
  
  const slugRef = useRef(null);
  const history = useHistory();
  const search = new URLSearchParams(window.location.search);
  const reschedule = search.get("reschedule") === "true";
  const uuid = search.get("uuid");
  const key = search.get("key");
  const localTimezoneOffset = new Date().getTimezoneOffset();

  function formatDuration(duration) {
    const [hours, minutes] = duration.split(':').map(Number);
    let formattedDuration = '';
    if (hours > 0) formattedDuration += `${hours}h `;
    if (minutes > 0) formattedDuration += `${minutes}m`;
    return formattedDuration.trim();
  }

  const answers = useMemo(() => {
    const params = history.location.search.substr(1);

    if (!params) {
      return {};
    }

    return params.split("&").reduce((acc, cur) => {
      const [key, value] = decodeURIComponent(cur).split("=");

      if (key === "reschedule" || key === "uuid" || key === "key") {
        return acc;
      }
      return {
        ...acc,
        [key]: value,
      };
    }, {});
  }, [history.location.search]);

  const adjustTimezone = (time, offset) => {
    const [hours, minutes, seconds] = time.split(':');
    const adjustedHours = parseInt(hours) - Math.floor(offset / 60);
    return `${adjustedHours.toString()}:${minutes}:${seconds}`
  }

  const [lastDate] = Object.keys(listOfDates).slice(-1);
  const maxDate = new Date(Date.parse(lastDate));

  useEffect(() => {
    if (answers.email) {
      const decodedEmails = JSON.parse(decodeURIComponent(answers.email));
      setEmail(decodedEmails[0]);
    }
  }, [answers.email]);

  useEffect(() => {
    const [startDate] =
      Object.entries(listOfDates).find(
        ([_, dateItems]) => (dateItems || []).length
      ) || [];

    if (startDate) {
      setDate(new Date(startDate));
    }
  }, [listOfDates]);

  const fetchData = (event) => {

    const eventForm = getEventType(event).then((response) => {
      const {
        data: { questions, available_dates: availableDates, slug },
      } = response;
      if (questions) {
        setListOfQuestions(questions);
      }
      if (availableDates) {
        setListOfDates(availableDates);
        slugRef.current = slug;
      }
      setIsReady(true);
    });

    const eventData = getEventData(event).then((response) => {
      const {
        data: { name: eventName, description, duration, paid, price, team, currency },
      } = response;
      setEventName(eventName);
      setDescription(description);
      setDuration(duration);
      setPaymentInfo({ paid, price, currency, event });
      setTeam(team);
    });
    return Promise.all([eventForm, eventData])
      .catch(catchError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDateChange = (date) => {
    let nextDay = new Date(date);
    setDate(nextDay);
    setCurrBooking({ date: null, startHour: null });
  };

  const [displayDate] = useMemo(() => {
    const now = new Date(date);
    return [format(now, "yyyy-MM-dd")];
  }, [date]);

  const select = useCallback(
    (e) => {
      if (!e.target.classList.contains("selected")) {
        setCurrBooking({ date: displayDate, startHour: e.target.innerText });
      }
    },
    [displayDate]
  );

  const rescheduleMeeting = (booking) => {
    const headers = key
      ? {
        authorization: `Token ${key}`,
      }
      : {};

    const MEETING_URL = `${process.env.REACT_APP_BACKEND_URL}/calendars/meetings/`;
    fetch(`${MEETING_URL}${uuid}/reschedule/`, { headers })
      .then((r) => {
        if (r.status === 200) {
          postMeetings(booking)
            .then(() => {
              history.push("/summary");
            })
            .catch((e) => {
              captchaRef.current.reset();
              setIsLoading(false);
              catchError(e);
            });
        }
      })
      .catch((e) => {
        catchError(e);
      });
  }

  const bookPaidMeeting = (booking) => {
    createPaidMeetingCheckout(paymentInfo.currency, paymentInfo.price, team, booking).then((result) => {
      const data = result.data;
      if (data !== null) {
          window.location.href = data;
      }
    }).catch((error) => {
      catchError(error);
    });
    setIsLoading(false);
  }

  const bookMeeting = (booking) => {
    postMeetings(booking)
      .then(() => {
        history.push("/summary");
      })
      .catch((e) => {
        captchaRef.current.reset();
        setIsLoading(false);
        catchError(e);
      });
  }

  const submitBooking = (e) => {
    e.preventDefault();
    clearError();
    // captchaRef.current.reset();

    setIsLoading(true);

    let answerList = [];
    const answerElems = document.querySelectorAll("input[class='question']");
    answerElems.forEach((answer, i) =>
      answerList.push({
        question: listOfQuestions[i],
        answer: answer.value,
      })
    );

    const booking = {
      email: email,
      start_hour: currBooking.startHour,
      date: currBooking.date,
      answers: answerList,
      event_type_slug: slugRef.current,
    };

    if (reschedule) {
      rescheduleMeeting(booking)
    } else if (paymentInfo.paid) {
      bookPaidMeeting(booking)
    } else {
      bookMeeting(booking)
    }
  };

  useEffect(() => {
    if (!event) {
      window.location.replace("/");
      return;
    }

    fetchData(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const calendar = listOfDates[displayDate] || [];

  const [isRobot, setIsRobot] = useState(true)
  const captchaRef = useRef(null)

  function onCaptchaChange(value) {
    setIsRobot(false)
  }

  if (!isReady && !isLoading) {
    return null;
  }

  if (!isReady) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  const handleDisabled = (date) => {
    const currentDate = format(date, "yyyy-MM-dd");
    return !listOfDates[currentDate] || listOfDates[currentDate].length === 0;
  };

  return (
    <Box sx={{ 
      backgroundColor: '#1834d4', 
      minWidth: '100vw', 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'end', 
      justifyContent: 'center', 
      overflowY: 'hidden', 
      position: 'relative' 
    }}>
    <img 
      src={SmrtlinksLogo} 
      alt="Logo" 
      style={{
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
        left: "5%",
        top: "5%",
        zIndex: 0
      }} 
    />
    <Box
      component="div"
      sx={{
        position: 'absolute',
        top: '30%',
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${Rectangle1})`,
        backgroundSize: 'cover',
        zIndex: 0,
      }}
    />
    <Box
      component="div"
      sx={{
        position: 'absolute',
        top: '20%',
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${Rectangle2})`,
        backgroundSize: 'cover',
        zIndex: 0,
      }}
    />
    <Paper 
      sx={{ 
        p: 4, 
        width: { xs: '100%', sm: '100%', md: '85%', lg: '70%' }, 
        borderTopLeftRadius: { xs: 0, md: 16 }, 
        borderTopRightRadius: { xs: 0, md: 16 }, 
        boxShadow: 3, 
        zIndex: 10, 
        marginTop: { xs: 0, sm: 0, md: "120px" }, 
        height: { xs: '100vh', md: '85vh' },
        overflowY: 'auto',
        borderBottomLeftRadius: 0, 
        borderBottomRightRadius: 0,
        scrollbarWidth: 'none',
      }}
    >
      <Grid container spacing={{ xs: 2, md: 1 }} justifyContent="center">
        <Grid item xs={12}>
          <div className="info">
            <p className="event-name">{eventName}</p>
            <pre className="description" dangerouslySetInnerHTML={{ __html: description }}/>
            <p className="duration">
              <Box component="span" sx={{ position: 'relative', top: '2px' }}>
                <WatchLaterIcon />
              </Box>
              &nbsp;Duration: {formatDuration(duration)}
            </p>          
          </div>
        </Grid>
        
        <Grid item xs={12} sm={7} md={6}>
          <Calendar
            minDate={new Date()}
            maxDate={maxDate}
            minDetail="month"
            maxDetail="month"
            view="month"
            onClickDay={(date) => handleDateChange(date)}
            tileDisabled={({ date }) => handleDisabled(date)}
            value={date}
          />
        </Grid>
  
        <Grid item xs={12} sm={5} md={6}>
          {calendar.length > 0 && (
            <Box className="hours">
              <Grid container spacing={{ xs: 1, sm: 2 }} >
                {calendar.map((hours, key) => {
                  const adjustedHours = calendar.length === 1 ? adjustTimezone(hours, localTimezoneOffset) : hours;
                  return (
                    <Grid
                      item 
                      xs={2}
                      sm={4}
                      lg={2}
                      key={`${hours.start_hour}${key}`}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        color="info"
                        onClick={select}
                        sx={{ mt: 1 }}
                        className={`calendar_button ${currBooking.startHour === adjustedHours.slice(0, -3) ? 'selected' : ''}`}
                      >
                        {adjustedHours.slice(0, -3)}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              height: '1px',
              backgroundColor: '#21CBEC',
              my: 3, 
              width: '100%', 
            }}
          />
        </Grid>
  
        <Grid item xs={12}>
          {calendar.length > 0 && (
            <form onSubmit={(e) => submitBooking(e)}>
              <Box mb={2}>
                <Typography variant="h6" color="primary.contrastText">
                  Enter your email
                </Typography>
                <input
                  type="email"
                  key="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  style={{
                    width: '100%',
                    padding: '4px',
                    paddingLeft: '12px',
                    border: 'none',
                    borderLeft: '2px solid',
                    borderRadius: 0,
                    borderLeftColor: email ? '#1834d4' : '#f2f2f2',
                    boxSizing: 'border-box',
                  }}
                />
              </Box>
              
              {listOfQuestions.map((question, key) => (
                <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        height: '1px',
                        backgroundColor: '#f2f2f2',
                        my: 3, 
                        width: '100%', 
                      }}
                    />
                  </Grid>
                  <Box mb={2} key={`${question}${key}`}>
                    <Typography variant="h6" color="primary.contrastText">
                      {question}
                    </Typography>
                    <input
                      className="question"
                      type="text"
                      required
                      defaultValue={answers[question]}
                      style={{
                        width: '100%',
                        padding: '4px',
                        paddingLeft: '12px',
                        border: 'none',
                        borderLeft: '2px solid',
                        borderRadius: 0,
                        borderLeftColor: answers[question] ? '#1834d4' : '#f2f2f2',
                        boxSizing: 'border-box',
                      }}
                    />
                  </Box>
                </>
              ))}
              
              {currBooking.date && (
                <>
                  <Typography variant="h6" color="primary.contrastText">
                    Selected date: {currBooking.date} {currBooking.startHour}
                  </Typography>
                  <ReCAPTCHA ref={captchaRef} onChange={onCaptchaChange} sitekey={process.env.REACT_APP_SITE_KEY} />
                </>
              )}
              
              <div style={{ display: 'flex', flexDirection: 'row', gap: "12px", marginTop: "12px" }}>
                <Button type="submit" className="bookButton" disabled={!currBooking.date || isRobot}>
                  {reschedule ? "Reschedule" : "Book"}
                </Button>
                <Button className="cancelButton" onClick={() => window.open( process.env.REACT_APP_ADMIN_PANEL)}>
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Grid>
      </Grid>
    </Paper>
  
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </Box>
  )
};

export default CalendarView;
