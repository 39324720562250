import './Nav.styles.scss';
import logo from '../../assets/onlight.png';
import { useLocation, matchPath } from "react-router-dom";

const Nav = () => {
    const location = useLocation();
    const hideNavForCalendarView = matchPath(location.pathname, {
        path: "/meetings/:event/new/",
        exact: true,
        strict: false,
    });
      
    return (
        <>
            { !hideNavForCalendarView &&
                <div className="Nav">
                    <img src={logo} alt="logo" />
                </div>
            }
        </>
    )
}

export default Nav;