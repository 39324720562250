import { useState } from 'react';

import './select.scss';

export default function Select({options, onChange, selectedId}) {
  const [selected, setSelected] = useState(selectedId);

  const handleChange = (e) => {
    const { value } = e.target;

    setSelected(value);
    onChange(value);
  };

  return (
    <select value={selected} onChange={handleChange}>
      {options.map(({ id, name, disabled }) => (
        <option key={id} value={id} disabled={disabled}>
          {name}
        </option>
      ))}
    </select>
  );
}
