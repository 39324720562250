import { Typography, List, ListItem, ListItemText } from "@mui/material";

const TermsOfUse = () => {

    return (
      <div style={{maxWidth: '90%', margin: '0 auto'}}>

        <Typography align="center" variant="h2">Terms of Use for SMRT:SYNC Software</Typography>
        <p>&nbsp;</p>
        <Typography variant="h4" width="80%" margin="0 auto">Effective September 1, 2023</Typography>
        <p>&nbsp;</p>
        <Typography variant="h4" width="80%" margin="0 auto">Thank you for choosing SMRT:SYNC software developed by SMRTLinks LTD. These Terms of Use 
        ("Terms" or "Agreement") constitute a legal agreement between you and SMRTLinks LTD ("SMRT:SYNC," "we," "our," or "us") 
        and govern your use of all the text, data, information, software, graphics, photographs, and other materials that we and 
        our affiliates may provide to you (collectively referred to as "Documentation"). This also includes our websites and any 
        services, plug-ins, software, or other downloadable tools (as defined below) that we offer through our websites 
        (the "Platform" or "Website" and collectively with the Documentation, the "Service(s)").</Typography>
        
        <div style={{maxWidth: '70%', margin: '0 auto'}}>
        <List sx={{ listStyle: "decimal", pl: 4, fontSize: "20px" }}>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary=" Acceptance of Terms" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="By using SMRT:SYNC, you acknowledge that you have read, understood, and agreed to these Terms. 
                If you do not agree with these Terms, please refrain from using the Service." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="User Categories" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Users of SMRT:SYNC are individuals who have created an SMRT:SYNC account (also referred to as Customers). 
                Invitees are individuals who have scheduled a meeting with an SMRT:SYNC User, whether or not they have registered with SMRT:SYNC. A Viewer is a person who visits the SMRT:SYNC Website, whether or not they are a User or Invitee.
                " />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary=" Authorized Users" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="If you are agreeing to these Terms on behalf of a company or organization, you represent and warrant that 
                you are authorized to do so and bind the company or organization to these Terms." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Dispute Resolution and Arbitration" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="These Terms include a dispute resolution and arbitration provision, including a class action waiver. 
                This provision affects your rights with respect to disputes you may have with SMRT:SYNC. Please review this section carefully." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Data Processing" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary=" If you are a User, Authorized User, Viewer, or Invitee located in the European Economic Area, 
                Switzerland, or the United Kingdom, the Data Processing Addendum accompanies these Terms. It outlines additional terms that apply specifically to any personal data of individuals located in these regions that you provide to SMRT:SYNC." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Modification of Terms" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="SMRT:SYNC reserves the right to modify these Terms at any time. Changes will become effective 
                upon posting on our Website. Continued use of the Service after changes constitutes acceptance of the modified Terms." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Termination of Use" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="You may discontinue using SMRT:SYNC at any time. SMRT:SYNC reserves the right to suspend or 
                terminate access to the Service for violation of these Terms or improper use.
                " />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Contact" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="For inquiries regarding the use of SMRT:SYNC or these Terms, please contact us at smrtlinks.com." />
            </ListItem>
        </List>
        </div>
        <Typography variant="h4" width="80%" margin="0 auto">By using SMRT:SYNC, you agree to be bound by these Terms of Use.</Typography>
                
      </div>
    );
  }
  
  export default TermsOfUse;