
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const NEED_LOGOUT_STATUSES = [401, 403];

export const DEFAULT_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const getHeaders = putToken => {
  const headers = {
    ...DEFAULT_HEADERS,
  };

  if (!putToken)
    return headers;

  return {
    ...headers,
    authorization: `Token ...`
  };
};

export const getResponseJson = response => {
  const { status, ok } = response;
  const responseData = response.json();

  return responseData.then(data => ({
    data,
    status,
    ok,
  }));
};

export const validateResponse = data => {
  const { ok } = data;
  if (!ok) {
    throw new Error(`--response-- ${JSON.stringify(data)}`);
  }
  
  return data;
};

export const catchError = e => {
  if (String(e).includes('Error: --response-- ')) {
    const stringForParse = String(e).replace('Error: --response-- ', '');
    const { status, data } = JSON.parse(stringForParse);
    if (NEED_LOGOUT_STATUSES.includes(status)) {
      // handle signout and refresh token page if needed
    };

    throw new Error(JSON.stringify(data));

  };
};

export const getError = message => {
  const error = {
      status: 0,
      message: 'Unknown error!'
    };

  try {
    const stringForParse = String(message).replace('Error: --response-- ', '');
    // https://www.django-rest-framework.org/api-guide/exceptions/
    const { status, data } = JSON.parse(stringForParse);
    const errorResult = { ...error, status };

    const entries = Object.entries(data);

    if (entries.length) {
      const message = entries.map(([key, value]) => {
        const [text] = Array.isArray(value) ? value : [value];
        return `${key}: ${text}`
      }).join('; ');

      return {
        ...errorResult,
        message,
      };
    }

    return errorResult;
  } catch (e) {
    return error;
  };
};

export default function http({
    method = 'get',
    url,
    payload,
    handleError = true,
    putToken = true,
  }) {
    const controller = new AbortController();
    const { signal } = controller;
    
    let body;

    try {
      body = JSON.stringify(payload);
    } catch (e) {
      return Promise.reject(e);
    };

    const request = fetch(`${BASE_URL}${url}`, {
      method,
      headers: getHeaders(putToken),
      body,
      signal
    })
      .then(response => getResponseJson(response))
      .then(data => validateResponse(data));
    
    if (handleError) {
      request.catch(catchError);
    };

    request.cancel = () => controller.abort();

    return request;
  };