import "./Notification.styles.scss"

export default function Notification ({ variant = 'error', status, message, handleClose }) {
  if (!message) {
    return null;
  }
  
  return (
    <div className="notification-container">
      <div className={`notification-${variant}`}>
        <h3>{status}: {message}</h3>
        <div className="button-container">
          <button onClick={handleClose}>X</button>
        </div>
      </div>
    </div>
  );
}
