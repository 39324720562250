const Summary = () => {

  return (
    <div className="summary view">
      <h1 className="sub">Your meeting has been scheduled succesfully!</h1>
      <small>All the details will be emailed to you shortly.</small>
    </div>
  );
}

export default Summary;