const ThankYou = () => {

  return (
    <div className="summary view">
      <h1 className="sub">You have been registered as a host successfully!</h1>

      <h2>You are now able to schedule and join smart meetings.</h2> <br/>

      <button onClick={() => window.open( process.env.REACT_APP_ADMIN_PANEL)} >Go to host panel</button>
    </div>
  );
}

export default ThankYou;