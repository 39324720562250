import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";

import { getMeeting } from "../../api/calendars";
import "./styles.scss";

export default function Meeting({ catchError }) {
  const { uuid } = useParams();
  const [meeting, setMeeting] = useState(null);
  const history = useHistory();

  const search = new URLSearchParams(history.location.search);
  const key = search.get("key");

  useEffect(() => {
    getMeeting(uuid)
      .then(({ data }) => {
        setMeeting(data);
      })
      .catch(catchError);
  }, [catchError, uuid]);

  if (!meeting) {
    return null;
  }

  const getQuestionString = (answers) => {
    return answers.reduce(
      (acc, el) => `${acc}&${el.question}=${el.answer}`,
      ""
    );
  };
  const getKeyString = (key) => (key ? `key=${key}` : "");

  const { status, event_type, answers, invitees } = meeting;
  const encodedInvitees = encodeURIComponent(JSON.stringify(invitees));

  // const displayStartTime = format(new Date(startTime), "yyyy-MM-dd H:mm");3

  if (status === 2) {
    return (
      <div className="view">
        <h1 className="sub">This meeting has been canceled</h1>
        <a href="https://smrtlinks.com/contact/">Return to a home page</a>
      </div>
    );
  }

  return (
    <Redirect
      to={`/meetings/${event_type}/new/?reschedule=${true}&uuid=${uuid}&email=${encodedInvitees}&${getKeyString(
        key
      )}${getQuestionString(answers)}`}
      reschedule={true}
      uuid={uuid}
      key={key}
    />
  );
}
