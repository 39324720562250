const NotRegistered = () => {
  return (
    <div className="summary view">
      <h1 className="sub">
        We couldn't sign you in, as your e-mail address is not present in our
        system. Please contact your company administrator for an invitation.
        Thank you.
      </h1>
    </div>
  );
};

export default NotRegistered;
