import { useCallback } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import SelectEvent from "./components/SelectEvent";
import LogIn from "./components/LogIn";
import CalendarView from "./components/Calendar/Calendar";
import Summary from "./components/Summary";
import Redirect from "./components/Redirect";
import ErrorPage from "./components/ErrorPage";
import ThankYou from "./components/ThankYou";
import SignupSuccessful from "./components/SignupSuccessful";
import Meeting from "./components/Meeting";
import NotRegistered from "./components/NotRegistered";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse"
import PrivacyPolicy from "./components/TermsOfUse/PrivacyPolicy"

import "./App.scss";
import { useState } from "react";
import Notification from "./components/Notification/Notification";
import { getError } from "./compositions/http/http";
import MeetingCanceled from "./components/Meeting/MeetingCanceled";
import SignUp from "./components/SignUp";

const App = () => {
  const [notification, setNotification] = useState(null);

  const catchError = useCallback((e) => {
    setNotification({
      variant: "error",
      ...getError(e),
    });
  }, []);

  const clearError = useCallback(() => {
    setNotification(null);
  }, []);

  return (
    <BrowserRouter>
      <div className="wrapper">
        <Nav />
        {notification && (
          <Notification
            variant={notification.variant}
            message={notification.message}
            status={notification.status}
            handleClose={clearError}
          />
        )}
        <div className="App">
          <Switch>
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/:teamId/events">
              <SelectEvent catchError={catchError} />
            </Route>
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/meetings/:event/new/">
              <CalendarView catchError={catchError} clearError={clearError} />
            </Route>
            <Route exact path="/edit/:uuid">
              <Meeting catchError={catchError} clearError={clearError} />
            </Route>
            <Route
              exact
              path="/meetings/canceled"
              component={MeetingCanceled}
            />
            <Route exact path="/summary" component={Summary} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/signup-successful" component={SignupSuccessful} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/signup/:uuid" component={Redirect} />
            <Route exact path="/404">
              <ErrorPage title="Oops... the link has expired" />
            </Route>
            <Route exact path="/not-registered" component={NotRegistered} />
            <Route
              component={() => {
                window.location.href = "https://smrtlinks.com/contact";
                return null;
              }}
            />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
