const SignupSuccessful = () => {

    return (
      <div className="summary view">
        <h1 className="sub">Your account was created successfully!</h1>
        <h2>You are now able to schedule and join smart meetings.</h2> <br/>
    
        <button onClick={() => window.open( process.env.REACT_APP_ADMIN_PANEL, '_self')} >Go to log in</button>
      </div>
    );
  }
  
  export default SignupSuccessful;