import { useState, useEffect, useCallback, useMemo} from "react";
import { Link, useParams } from "react-router-dom";
import queryString from 'query-string'

import Select from './Select';
import { getEventTypes } from '../api/calendars';

const SelectEvent = ({ catchError }) => {
  const [event, setEvent] = useState("default");
  const [events, setEvents] = useState([]);
  const { teamId } = useParams()

  useEffect(() => {
    const parsedTeamId = Number(teamId)
    const params = queryString.stringify({
      team__id: Number.isNaN(parsedTeamId) ? undefined : teamId,
      team__slug: Number.isNaN(parsedTeamId) ? teamId : undefined,
    });

    getEventTypes(params).then(({ data: { results } }) => {
      setEvents(results);
    }).catch(catchError);
    // we really want this function only on component init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSelectedEvent = useMemo(() => event !== 'default', [event])

  const clickNewMeeting = useCallback((e) => {
    if (!hasSelectedEvent) {
      e.preventDefault();
    }
  }, [hasSelectedEvent]);

  const handleGoBack = (e) => {
    e.preventDefault();

    window.history.back();
  }

  if (!events.length) {
    return (
      <div className="select-event-no-events">
        <h1>There are none events to display here.</h1>
        <div>
          <Link
            to="/"
            onClick={handleGoBack}
          >
            &laquo; Go back to previous page
          </Link>
        </div>
      </div>
    );
  }

  const options = [
    {
      id: 'default',
      name: 'Select event type to create...',
      disabled: true,
    },
    ...events
  ];

  return (
    <div className="select-event view">
      <p>Please select the type of meeting, You’d like to schedule.</p>
      <div className="select-event-form">
        <Select options={options} onChange={setEvent} selectedId={event} />
        <div>
          <Link
            to={`/meetings/${event}/new`}
            className={hasSelectedEvent ? '' : 'disabled'}
            onClick={clickNewMeeting}
          >
            Next
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SelectEvent;
