const MeetingCanceled = () => {
  return (
    <div className="view">
      <h1 className="sub">Meeting was successfully canceled</h1>
      <a href="https://smrtlinks.com/contact/">Return to a home page</a>
    </div>
  );
};

export default MeetingCanceled;
