const ErrorPage = ({ title }) => {
  return (
    <div className="view">
      <h1 className="sub">{title}</h1>
      <a href="https://smrtlinks.com/contact/">Return to a home page</a>
    </div>
  );
};

export default ErrorPage;
