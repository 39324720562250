import http from "../compositions/http";

export const getEventType = eventType =>
  http({
    url: `/calendars/event-types/${eventType}/form`,
    handleError: true,
    putToken: false,
  });

export const getEventData = event =>
  http({
    url: `/calendars/event-types/${event}`,
    handleError: true,
    putToken: false,
  });

export const postMeetings = payload =>
  http({
    method: 'POST',
    url: '/calendars/meetings/',
    handleError: true,
    putToken: false,
    payload
  });
  
export const getMeeting = uuid => http({
  url: `/calendars/meetings/${uuid}`,
  handleError: true,
  putToken: false,
});

export const patchMeeting = (uuid, payload) => http({
  method: 'PATCH',
  url: `/calendars/meetings/${uuid}/`,
  handleError: true,
  putToken: false,
  payload,
});

export const getEventTypes = (params) =>
  http({
    url: `/calendars/event-types?${params}`,
    handleError: true,
    putToken: false,
  });
