import { Typography, List, ListItem, ListItemText } from "@mui/material";

const PrivacyPolicy = () => {

    return (
        <div style={{maxWidth: '90%', margin: '0 auto'}}>

        <Typography align="center" variant="h2">Privacy Notice for SMRT:SYNC Software</Typography>
        <p>&nbsp;</p>
        <Typography variant="h4" width="80%" margin="0 auto">Effective September 1, 2023</Typography>
        <p>&nbsp;</p>   
        <Typography variant="h4" width="80%" margin="0 auto">At SMRTLinks LTD, the developer of SMRT:SYNC software, we are dedicated to 
        helping you schedule better. To achieve this, we collect personal information from you and the individuals scheduling time with you. 
        We understand the importance of your privacy and rights, and we want you to use our services with confidence and trust in our 
        interactions. We hope you find SMRT:SYNC as valuable as we do, but if you disagree with this policy, please refrain from accessing 
        or using the Website.</Typography>
        <p>&nbsp;</p>
        <Typography variant="h4" width="80%" margin="0 auto">We have provided the following sections to address the key aspects of our 
        privacy practices. We have aimed to present this information in a clear and straightforward manner, though we acknowledge that 
        it can be quite extensive. If you have any further questions, please feel free to contact us via email.</Typography>
        
        <div style={{maxWidth: '70%', margin: '0 auto'}}>
        <List sx={{ listStyle: "decimal", pl: 4, fontSize: "20px" }}>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Information Collection" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="We collect personal information to enhance your experience with SMRT:SYNC and improve our services. 
                This includes details provided by you and those scheduling time with you." />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="How We Use Your Information" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="We use the collected information to provide you with an efficient scheduling experience and to enhance our services. 
                Your data helps us personalize your interactions and optimize our offerings." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Data Sharing" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="We may share your information with select third parties to facilitate the scheduling process and improve our services. 
                However, we do not sell your personal information to any third parties." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Security Measures" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="We employ industry-standard security measures to safeguard your data and protect it from unauthorized access, disclosure, 
                alteration, or destruction." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Your Choices" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="You have the right to manage your personal information and choose the extent to which you share it with us. 
                You can update your preferences and make choices regarding data collection and usage." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Children's Privacy" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="SMRT:SYNC is not intended for use by individuals under the age of [insert age]. We do not knowingly 
                collect personal information from children under this age." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Changes to this Notice" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="This Privacy Notice may be updated from time to time. We will notify you of any material changes, 
                and the revised version will be available on our Website." />
            </ListItem>
            <ListItem sx={{ display: "list-item"}}>
                <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="Contact Us" />
            </ListItem>
            <ListItem sx={{pl: 4}}>
            <ListItemText primaryTypographyProps={{fontSize: '18px'}}  
                primary="For inquiries regarding your privacy and data usage, or to exercise your rights related to your personal information, 
                please contact us via email at smrtlinks.com" />
            </ListItem>
            </List>
        </div>
        <Typography variant="h4" width="80%" margin="0 auto">Thank you for entrusting SMRT:SYNC with your scheduling needs. 
        Your privacy is important to us, and we are committed to ensuring a secure and transparent experience for all our users.
        </Typography>
                
      </div>
    );
  }
  
  export default PrivacyPolicy;