import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App.jsx';
import { stopReportingRuntimeErrors } from "react-error-overlay";

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors();
} else {
  Sentry.init({
    dsn: "https://ca3ffb01874249a5908a0daee9d748dc@o511280.ingest.sentry.io/5723027",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
