import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import GoogleIcon from '@mui/icons-material/Google';
import { useState, useRef } from "react";
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha"
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright ©'}
            &nbsp;
            <b>smrtlinks.com</b>
            &nbsp;
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {

    const history = useHistory();
    const [hasCompany, setHasCompany] = useState(false)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        email_repeat: "",
        company: "",
        password: "",
        password_repeat: "",
    })

    const [isRobot, setIsRobot] = useState(true)
    const captchaRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false);

    function onCaptchaChange(value) {
        setIsRobot(false)
    }

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        captchaRef.current.reset();

        if(!validateForm()) {
            setIsRobot(true)
            return;
        }
        fetch(`${process.env.REACT_APP_BACKEND_URL}/users/register/`, {
            method: 'POST',
            body: JSON.stringify( formData ),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
            .then(() => {
                setIsLoading(false);
                history.push("/signup-successful");
            })
                .catch((error) => {
                    setIsLoading(false);
                    console.error("Error", error);
                });
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData, [name]: value,
        }));
    }

    function validateForm() {
        const newErrors = {}
        if (!formData.lastName) {
            newErrors.lastName = "Last name is required";
        }
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Please enter a valid email adress";
        }
        if (hasCompany && !formData.company) {
            newErrors.company = "Company name is required"
        }
        if (formData.password.length < 8) {
            newErrors.password = "Password should have at least 8 characters";
        }
        if (formData.email !== formData.email_repeat) {
            newErrors.email_repeat = "Emails don't match";
        }
        if (formData.password !== formData.password_repeat) {
            newErrors.password_repeat = "Passwords don't match";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Typography align="center" component="h1" variant="h3">
                            SMRT: Sync
                        </Typography>
                        <Typography align="center" component="h1" variant="h5">
                            Sign up for FREE
                        </Typography>
                    </Stack>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    error={Boolean(errors.firstName)}
                                    helperText={errors.firstName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            < Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                    error={Boolean(errors.lastName)}
                                    helperText={errors.lastName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    type="email"
                                    id="email_repeat"
                                    label="Repeat Email Address"
                                    name="email_repeat"
                                    error={Boolean(errors.email_repeat)}
                                    helperText={errors.email_repeat}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        < Checkbox name = "hasCompany" checked = {hasCompany}
                                        onChange={() => setHasCompany((prevHasCompany) => !prevHasCompany)} />
                                    }
                                    label="Register as a company."
                                />
                            </Grid>
                            { hasCompany && <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="company"
                                    label="Company name"
                                    name="company"
                                    autoComplete="company"
                                    error={Boolean(errors.company)}
                                    helperText={errors.company}
                                    onChange={handleChange}
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    error={Boolean(errors.password)}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password_repeat"
                                    label="Repeat Password"
                                    type="password"
                                    id="password_repeat"
                                    error={Boolean(errors.password_repeat)}
                                    helperText={errors.password_repeat}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                <ReCAPTCHA ref={captchaRef} onChange={onCaptchaChange} sitekey={process.env.REACT_APP_SITE_KEY} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        <Button
                            disabled={isRobot}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 1 }}
                        >
                            Sign Up
                        </Button>
                        {/* <Button
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2 }}
                            href={`${process.env.REACT_APP_BACKEND_URL}/users/admin-panel-login/?provider=google`}
                        >
                            <GoogleIcon /> &nbsp;
                            Sign Up With Google
                        </Button> */}
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link href={process.env.REACT_APP_ADMIN_PANEL}>
                                    Already have an account?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
            <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} >
                <CircularProgress />
            </Backdrop>
        </ThemeProvider>
    );
}