import { Link } from 'react-router-dom';

const LogIn = () => {

  return (
    <div className="login view">
      <h1 className="sub">Google log in</h1>
      <h1 className="sub">My Google Login Project</h1>
      <Link to="/calendar"><button>Login with Google</button></Link>
    </div>
  );
}

export default LogIn;
