import http from "./http"

export const PAYMENT_INFO = '/payments/make-single-payment/'
export const PUBLIC_KEY = '/payments/stripe-public-key/'
export const PAID_MEETING_CHECKOUT = '/payments/meeting/create-checkout-session/'

export const getPublicKey = (data) => {
  const params = new URLSearchParams(data).toString();
  return http({
    url: `${PUBLIC_KEY}?${params}`,
    putToken: false,
    handleError: true,
  });
}

export const postSinglePayment = (data) =>
  http({
    method: 'post',
    url: PAYMENT_INFO,
    putToken: false,
    handleError: true,
    payload: data
  });

export const createPaidMeetingCheckout = (currency, price, team, booking) =>{
  const params = encodeURIComponent(JSON.stringify(booking));
  return http({
    url: `${PAID_MEETING_CHECKOUT}?currency=${currency}&price=${price}&team=${team}&booking=${params}`,
    putToken: false,
    handleError: true,
  });
}
